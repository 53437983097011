
.es-project {
  margin: auto 0;
}

.offset .header {
  position: fixed;
}

.offset .header {

  .main-navigation li a {
    padding: 10px 34px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .navigation-top, .navigation-icons {

    ul.menu, ul.icons {
      margin-top: 5px;
      margin-bottom: 5px;
    }

  }

}

.mobile-header {

  .main-navigation li a {
    padding: 10px 34px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .navigation-top, .navigation-icons {

    ul.menu, ul.icons {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

  }
}

.spacer {
  width: 170px;
}

.header {
  position: relative;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  padding: 0;
  background: #FFFFFF;
  box-shadow: 0 1px 5px -2px rgba(42,42,42,.4);

  .wrap {
    display: flex;
    justify-content: space-between;
  }

  .logo {
    margin: auto 0;
    min-width: 200px;
    text-align: center;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }

  ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  .search {
    margin: auto;
  }

  .lang {

    li.current-lang a {
      font-weight: bold;
    }

    li {
      margin: 0 !important;

      a {
        text-transform: uppercase;
      }

      &::after {
        content: "/";
        color: #fff;
        font-family: $font_regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
      }
    }

    li:last-child::after {
      content: none;
    }
  }

  .navigation {
    width: 100%;
    margin: auto;
    z-index: 1000;

    li a {
      &:hover {
        color: $active_color;
      }
    }
  }

  .navigation-top {
    background: #1F50A0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .wrap {
      margin: auto 0;
      justify-content: flex-end;
    }

    .navigation-top-secondary, .navigation-icons {

      &::before {
        content: "";
        border-left: 1px solid #FFFFFF;
        top: 0;
        bottom: 0;
        position: absolute;
        height: 20px;
        margin: auto 0;
      }

      i {
        cursor: pointer;
      }

    }

    .navigation-top-secondary ul.menu li {
      margin-left: 20px;
    }

    .navigation-icons ul li {
      margin-left: 15px;
    }

    ul.menu {
      margin-right: 20px;
      margin-left: 20px;

      li:first-child {
        margin-left: 0;
      }

      li {
        margin-left: 30px;
      }

    }

    ul li a {
      color: #FFFFFF;
      font-family: $font_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 106.8%;
      letter-spacing: normal;
      text-decoration: none;
    }
  }

  .main-navigation {
    background: #F3F3F3;

    ul {
      margin: auto 0;
      justify-content: center;

      li:hover > .sub-menu {
        display: block;
        max-height: 80vh;
        overflow: auto;
      }
    }

    .sub-menu {

      &::-webkit-scrollbar {
        width: 0;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
      }

    }

    ul > li.menu-item-has-children > a {
      &::after {
        content: "";
        background: url('assets/images/menu-arrow.svg') no-repeat;
        width: 12px;
        height: 5px;
        display: inline-block;
        position: absolute;
        right: 18px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    ul.sub-menu {
      position: absolute;
      width: 250%;
      display: none;
      z-index: 10;
      box-shadow: 0 1px 5px -2px rgba(42,42,42,0.4);

      > li.menu-item-has-children > a {
        &::after {
          content: "";
          filter: invert(1);
          transform: rotate(-90deg) translate(1px, 0px);
        }
      }

      li {
        background: #5CB97F;
        border: 1px solid #5CB97F;
        border-left: 5px solid transparent;
        width: 200px;

        &:hover {
          border-left: 5px solid #FFFFFF;
        }

        ul.sub-menu {
          left: 100%;
          top: 0;

          li {
            background: #49B170;
          }
        }
      }

      a {
        border: none;
        color: #ffffff;
        padding: 11px 13px;
      }

    }

    li {
      position: relative;

      a {
        color: $header_color;
        font-family: $font_regular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: normal;
        text-decoration: none;
        position: relative;
        border: 1px solid #E6E6E6;
        margin: 0;
        transition: .5s;
        padding: 23px 34px;
        display: block;
        margin: 0 -0.5px;
      }
    }

  }

  .btn {
    background: #49B170;
    display: block;
    border-radius: 24px;
    margin: auto 0;
    padding: 8px 32px;
    transition: .5s;

    &:hover {
      background: #1F50A0;
    }

    span {
      font-family: $font_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 106.8%;
      text-align: center;
      color: #FFFFFF;
    }
  }

}

.mobile-menu {
  background: url(assets/images/menu.svg) 100% no-repeat;
  width: 20px;
  height: 20px;
  position: relative;
  margin: auto 0 auto 0;
  display: none;
  z-index: 10000;
}

body .mobile-header {
  position: fixed;
  width: 100%;
  z-index: 10000;
  background: #fff;

  .navigation {
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    overflow: auto;
  }

  .logo {
    display: none;
  }

  .main-navigation {
    order: -1;
  }

  .navigation-top {
    width: 100%;
  }

  .navigation-top, .navigation-icons {

    ul.icons {
      margin-left: 10px;
    }

  }

  .mobile-menu {
    display: block;
    order: -1;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 20px;
    padding: 10px;
  }

  .navigation .main-navigation {
    width: 100%;
  }

  .navigation .main-navigation .wrap {
    background: #fff;
    width: 100%;
    padding: 0;

    .es-project {
      display: none;
    }

    .btn {
      display: none;
    }
  }

  .navigation .main-navigation ul {
    width: 100%;
    position: relative;
    max-height: unset;
    margin-top: 0;
    background: #fff;

    ul {
      margin-top: 0;
      left: 0 !important;
    }
  }

  .lang {
    margin-left: 0 !important;
  }

  .navigation-top ul.menu {
    margin: 0;
  }

  .navigation-top {
    display: block;
    position: relative;
    z-index: 100;
    padding: 0;

    .wrap {
      padding: 0;

      ul, div.navigation-top-secondary, div.navigation-icons {
        display: block;
        width: 100%;
        overflow: hidden;

        &::before {
          content: none !important;
        }
      }
    }

    ul.menu li {
      margin-left: 0 !important;
      padding: 10px;
      width: 100%;
    }

    ul {
      display: block;

      li {
        display: inline-block;
        margin-left: 10px;
      }
    }

  }

  .main-navigation .menu {
    position: relative;
    overflow: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 98%;

      a {
        padding: 10px 0 10px 10px;
        display: block;
      }
    }
  }

}

.search-wrap-open {
  display: block !important;
}

.search-wrap {
  display: none;
  position: absolute;
  z-index: 1000;
  max-width: 320px;
  width: 100%;
  right: 20px;
  top: 0;
  background: #ffffff;
  margin: auto;
  box-shadow: 0 20px 40px rgba(0,0,0,0.25), 0 5px 10px rgba(0,0,0,0.2);

  .submit {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
  }

  input {
    width: 100%;
    padding: 15px 40px 15px 15px;
    outline: none;
  }

}

@media only screen and (min-width: 1750px) {

  .header {
    .main-navigation .wrap, .navigation-top .wrap {
      transform: translate(-100px, 0px);
    }
  }

}

@media only screen and (max-width: 1515px) {

  .header .main-navigation li a {
    font-size: 14px;
    padding: 20px 34px 20px 20px;
  }

  .offset .header .main-navigation li a {
    font-size: 14px;
    padding: 10px 34px 10px 20px;
  }

}

@media only screen and (max-width: 1380px) {

  .header .logo {
    min-width: 100px;
  }

  .mobile-header.header .navigation {
    width: unset;
  }

}

@media only screen and (max-width: 1080px) {

  .header .main-navigation {
    background: transparent;
  }

  .spacer {
    width: auto;
  }

  .header .navigation {
    ul {
      display: none;
    }
  }

  .mobile-menu {
    display: block;
  }

  .primary-menu {
    display: none;
  }

}

@media only screen and (max-width: 700px) {

  .header .logo {
    margin: auto;
  }

  .header .navigation {
    width: 100%;
  }

}

@media only screen and (max-width: 500px) {

  .header .btn {
    padding: 8px 12px;

    span {
      display: none;
    }
  }

  .header .btn {
    background: transparent !important;
  }

  i.user-icon {
    width: 13px;
    height: 15px;
    display: inline-block;
    transform: translate(-2px, 1px) scale(1.5);
    filter: invert(1);
  }

  .es-project {
    img {
      height: auto;
      width: 110px;
    }
  }



}

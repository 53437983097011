
.hidden {
  display: none;
}

#back-to-top {
  position: fixed;
  padding: 15px 20px;
  bottom: 30px;
  right: 30px;
  background: $active_color;
  box-shadow: 0 1px 5px -2px rgba(42,42,42,0.4);
  z-index: 10000;

  &:hover {
    background: #1F50A0;
  }
}

footer {
  background: #1F50A0;

  .bottom {
    margin: 70px 0 30px 0;

    p {
      margin: 0;
    }
  }

  .socials {
    width: 18%;
  }

  .wrap {
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;
    justify-content: space-between;
  }

  h4, .h4 {
    color: #FFFFFF;
    font-family: $font_bold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 138.8%;
    text-transform: uppercase;

    &::after {
      content: "";
      border-bottom: 1px solid #FFFFFF;
      display: block;
      margin: 13px 0 17px 0;
      width: 73px;
    }
  }

  .about {

    p, a {
      font-size: 12px;
    }

    .text {
      max-width: 242px;
    }

  }

  p, a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 138.8%;
    color: #FFFFFF;
    margin-bottom: 0;
  }

  .text {
    max-width: 253px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    li {
      width: 25%;
    }
  }

  .socials a {
    margin-right: 10px;
    display: inline-block;
  }

}

@media only screen and (max-width: 800px) {

  footer ul li {
    width: 50%;
  }

  #back-to-top {
    display: none;
  }

}

@media only screen and (max-width: 500px) {

  footer {

    ul li {
      width: 100%;
    }

    .socials {
      width: 100%;
    }

    .bottom {
      margin: 20px 0;
    }

    h4, .h4 {
      margin-top: 20px;
    }

  }

}
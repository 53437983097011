@font-face {
  font-family: 'Work Sans Light';
  src: url('assets/fonts/WorkSans-Light.eot');
  src: url('assets/fonts/WorkSans-Light.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/WorkSans-Light.woff2') format('woff2'),
  url('assets/fonts/WorkSans-Light.woff') format('woff'),
  url('assets/fonts/WorkSans-Light.ttf') format('truetype'),
  url('assets/fonts/WorkSans-Light.svg#WorkSans-Light') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans Regular';
  src: url('assets/fonts/WorkSans-Regular.eot');
  src: url('assets/fonts/WorkSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/WorkSans-Regular.woff2') format('woff2'),
  url('assets/fonts/WorkSans-Regular.woff') format('woff'),
  url('assets/fonts/WorkSans-Regular.ttf') format('truetype'),
  url('assets/fonts/WorkSans-Regular.svg#WorkSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans Medium';
  src: url('assets/fonts/WorkSans-Medium.eot');
  src: url('assets/fonts/WorkSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/WorkSans-Medium.woff2') format('woff2'),
  url('assets/fonts/WorkSans-Medium.woff') format('woff'),
  url('assets/fonts/WorkSans-Medium.ttf') format('truetype'),
  url('assets/fonts/WorkSans-Medium.svg#WorkSans-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans Bold';
  src: url('assets/fonts/WorkSans-Bold.eot');
  src: url('assets/fonts/WorkSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/WorkSans-Bold.woff2') format('woff2'),
  url('assets/fonts/WorkSans-Bold.woff') format('woff'),
  url('assets/fonts/WorkSans-Bold.ttf') format('truetype'),
  url('assets/fonts/WorkSans-Bold.svg#WorkSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$font_bold: 'Work Sans Bold';
$font_medium: 'Work Sans Medium';
$font_regular: 'Work Sans Regular';
$font_light: 'Work Sans Light';

$header_color: #000000;
$text_color: #777777;
$active_color: #49B170;
@import "fonts";
@import "icons";

.wp-block {
  max-width: 1080px !important;
}

.wp-block-button__link {
  font-family: $font_medium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
  background: #5CB97F;
  border-radius: 38px;
  padding: 17px 70px;
}

.wp-block-media-text {
  margin-bottom: 20px;
}

.wp-block-column {
  margin: 0 !important;

  .news-block {
    padding-top: 16px !important;
  }
}

.wp-block-quote {

  p {
    font-family: $font_bold;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 138.8%;
    color: #000000;
  }

  cite {
    font-family: $font_light;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 138.8%;
    text-align: center;
    color: #000000;
  }

}

.justify-text {
  text-align: justify;
  display: inline-block;
}

.arrow-link {

  &::after {
    content: "";
    background: url(assets/images/calendar-arrow.svg) 100% no-repeat;
    transform: rotate(180deg) translate(-10px, 0px);
    display: inline-block;
    width: 30px;
    height: 12px;
  }
}

body {

  .swiper-pagination {
    margin-bottom: 30px;
  }

  .swiper-pagination-bullet {
    background: transparent;
    border: 1px solid #1392B3;
    height: 10px;
    width: 10px;
    box-sizing: border-box;
  }

  .swiper-pagination-bullet-active {
    background: #1392B3;
    border: 1px solid #1392B3;
    height: 10px;
    width: 10px;
    box-sizing: border-box;
  }

}

.statistics-block {

  ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin: 0;
  }

  li {
    width: 150px;
    position: relative;
  }

  li:first-child::after {
    content: none;
  }

  li::after {
    content: "";
    border-left: 1px solid #A5A5A5;
    transform: rotate(15deg) translate(-65px, -60%);
    top: 60%;
    bottom: 0;
    height: 70%;
    position: absolute;
  }

  .number {

    h3 {
      font-family: $font_light;
      font-style: normal;
      font-weight: 300;
      font-size: 60px;
      line-height: 138.8%;
      color: #000000;
      text-align: center;
    }

    h5 {
      font-family: $font_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 162.8%;
      text-align: center;
      color: #000000;
    }

  }

}

.info-boxes {
  position: relative;
  background: #F3F3F3;

  .wrap {
    z-index: 1000;
  }

  .layout-1 {

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
      background: #3B66AC;
      padding: 0;
      margin: -60px 0 0 0;
      overflow: hidden;

      li {
        padding: 30px;
        width: calc(33.3% - 90px);
        transition: .5s;

        &:hover {
          transform: translate(15px, 0px) scale(1.1);

          span {
            color: $active_color;
          }

          img {
            filter: brightness(0) saturate(100%) invert(59%) sepia(74%) saturate(346%) hue-rotate(90deg) brightness(89%) contrast(81%);
          }

        }

        a {
          margin: auto 0;
          transform: translate(0px, -15px);
          display: block;
        }
      }
    }

    .no-image a {
      transform: translate(0px, 15px);
    }

    .last-child {
      background: #49B170;
      transform: skewX(-30deg) translate(40px, 0px) !important;
      text-align: center;
      transition: .75s;
      border-left: 1px solid transparent;

      &:hover {
        background: #3B66AC;
        border-left: 1px solid #fff;

        span {
          color: #ffffff;
        }
      }

      span {
        padding-right: 60px;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
      }

      a {
        transform: skewX(30deg) translate(0px, 15px);

        &::after {
          content: '';
          background: url('assets/images/arrow.svg') 100% no-repeat;
          width: 30px;
          height: 10px;
          display: inline-block;
          transform: translate(20px, -2px);
        }
      }
    }

    img {
      display: inline-block;
      transform: translate(0px, 15px);
    }

    span {
      font-family: $font_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 29px;
      text-align: center;
      color: #FFFFFF;
      display: inline-block;
    }

  }

  .layout-2 {
    background: #1F50A0;
    padding: 0;
    margin: 0;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
      padding: 0;
      margin: 0;

      li:first-child::after {
        content: none;
      }

      li {
        padding: 50px 30px;
        width: calc(33.3% - 60px);
        transition: .5s;

        &:hover {
          transform: translate(0px, 0px) scale(1.05);

          span {
            color: $active_color;
          }

          img {
            filter: brightness(0) saturate(100%) invert(59%) sepia(74%) saturate(346%) hue-rotate(90deg) brightness(89%) contrast(81%);
          }

        }

        &::after {
          content: "";
          border-left: 1px solid #A5A5A5;
          transform: rotate(15deg) translate(-25px, -50%);
          top: 50%;
          bottom: 0;
          height: 50%;
          position: absolute;
        }
      }
    }

    .image-wrap {
      height: 120px;
      position: relative;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    img {
      display: block;
      margin: auto;
      max-width: 95px;
      max-height: 95px;
    }

    span {
      font-family: $font_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 27px;
      line-height: 32px;
      text-align: center;
      color: #FFFFFF;
      display: block;
    }

  }

}

.accordion-block {

  .accordion-wrap .accordion-item {
    border: none;
    margin: 0;

    .accordion-content {
      background: transparent;
      padding-top: 5px;
    }
  }

}

.accordion-block.-design-2 {

  .accordion-item.active {

    .accordion-header {
      border-bottom: 1px solid #1F50A0;
    }

    .accordion-content {
      display: flex !important;
      flex-wrap: wrap;
    }

  }

  .btn {
    text-align: center;
    color: #FFFFFF !important;
    background: #5CB97F;
    border-radius: 38px;
    padding: 17px 70px;
    text-decoration: none !important;
  }

  .inner-link {
    margin: auto 0 auto auto;
  }

  .inner-content-wrap {
    max-width: 908px;
  }

  .accordion-item {
    border: 1px solid #1F50A0;
    margin-top: -1px;

    .accordion-header {

      &:after {
        content: '';
        background: url("assets/images/arrow-top-blue.svg") 100% no-repeat;
      }

      .accordion-title {
        color: #1F50A0;
      }

    }

    .accordion-content {
      background: #F5F5F5;

      a {
        text-decoration: underline;
      }

      p, a, li, span {
        font-family: $font_regular;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 184.8%;
        color: #000000;
      }
    }

  }

}

.accordion-wrap {
  margin: 40px 0;

  .accordion-item {
    border: 1px solid #5CB97F;
    margin: -1px 0;

    .accordion-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 17px;
      position: relative;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
        width: 17px;
        height: 10px;
        background: url("assets/images/arrow-top.svg") 100% no-repeat;
        transition: .75s;
      }

      .accordion-title {
        font-family: $font_bold;
        color: $active_color;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 106.8%;
        margin: 0;
        padding: 0;
        margin-right: 50px;
      }
    }

    &.active {

      .accordion-header:after {
        transform: translateY(-50%) rotate(180deg);
      }

      .accordion-content {
        display: block;
      }

    }

    .accordion-content {
      padding: 25px 40px;
      background: #F5F5F5;
      overflow: auto;

      table {
        width: 100% !important;

        td {
          padding: 10px 5px;
        }

        tr:nth-child(odd) {
          background-color: #f0f0f0;
        }

      }

      ul {
        padding: 0;
      }
    }
  }

  .collapsed .accordion-content {
    height: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

}

.carousel-block {
  position: relative;
  background: #E2F2F6;

  iframe {
    height: 56.25vw;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
  }

  .navigation-buttons {
    position: absolute;
    bottom: 100px;
    z-index: 100;
    display: flex;

    div:first-child {
      transform: rotate(180deg) translate(0px, -1px);
    }

    div:last-child {
      margin-left: 28px;
    }

    div {
      background: url('assets/images/arrow-button.svg') 100% no-repeat;
      width: 50px;
      height: 50px;
      cursor: pointer;

      &:hover {
        filter: brightness(0) saturate(100%) invert(59%) sepia(74%) saturate(346%) hue-rotate(90deg) brightness(89%) contrast(81%);
      }
    }

  }

  .wrap {
    position: relative;
    width: 100%;
    margin: auto;
    z-index: 10;
  }

  h1, .h1 {
    color: #f1f1f1;
    font-family: $font_bold;
    font-style: normal;
    font-weight: bold;
    font-size: 67px;
    line-height: 79px;
    text-align: center;
    text-transform: uppercase;
  }

  .btn {
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 38px;
    padding: 16px 35px;
    margin-top: 40px;
    transform: translate(0px, 80px);
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    &:hover {
      background: #ffffff;
      color: #000000;
    }
  }

  .text-content {
    margin: 20px 0 0 0;
    overflow: hidden;

    p {
      transform: translate(0px, 30px);
    }
  }

  .text-content, p, a, span {
    font-family: $font_regular;
    font-style: normal;
    font-weight: normal;
    font-size: 27px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
  }

  .slider {
    position: relative;
  }

  .slider-wrapper {
    overflow: hidden;
    margin: auto;
    position: relative;
  }

  .swiper-wrapper {
    padding: 0;
    margin: 0;

    .swiper-slide {
      list-style: none;
      overflow: hidden;

      .content {
        opacity: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: auto;
        height: auto;
        min-height: 510px;
        text-align: center;
        position: relative;
      }
    }
  }

  .header-wrap {
    overflow: hidden;
  }

  .slider-wrapper .animated-out {

    .content {

      h1, p {
        transform: translate(0px, 120px) !important;
        transition: 1s;
      }
    }

  }

  .animated {

    .content {
      opacity: 1 !important;
      animation: 3s textZoomOut;
    }

    .background {
      animation: 2s imageZoomOut;
    }

    .text-content {
      p {
        transform: translate(0px, 0px) !important;
        transition: 2s;
      }
    }

    .btn {
      transform: translate(0, 0);
      transition: 1s;
    }

  }

  .background {
    margin: auto 0;
    display: flex;
    height: auto;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    min-height: calc(100vh - 120px);
  }

}

@keyframes textZoomOut {
  0% {
    transform: scale(1.2);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    transform: scale(1);
  }
}

@keyframes imageZoomOut {
  0% {
    background-size: 200%;
  }
  50% {
    background-size: 100%;
  }
  100% {
    background-size: cover;
  }
}

.list-table-block.-design-2 {

  .column-value div {
    max-width: 400px;
  }

  .column-value {
    display: flex;
    flex-wrap: wrap;

    .image-wrap {
      margin-right: 28px;
    }
  }

  .column-label {

    strong {
      color: #1F50A0;
    }

    p, a, li, span {
      color: #000000;
    }
  }

  .column-label, .column-value {
    width: calc(50% - 60px);
  }

  p, a, li, span {
    margin-bottom: .3rem;
    font-size: 14px;
  }

  a {
    text-decoration: underline;
  }

}

.list-table-block.-design-1, .list-table-block.-design- {

  p, a, li, span {
    margin: 0;
  }

}

.list-table-block {
  margin: 50px 0;

  .table {
    display: flex;
    flex-wrap: wrap;
  }

  .column-label {
    border: 1px solid #1F50A0;
    padding: 23px 28px;
    width: calc(35% - 60px);

    div, p, a, li, span {
      font-family: $font_regular;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 202.8%;
      color: #1F50A0;
    }
  }

  .column-value {
    background: #1F50A0;
    padding: 23px 30px;
    width: calc(65% - 60px);

    div, p, a, li, span {
      font-family: $font_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 202.8%;
      color: #ffffff;
    }
  }

}

.big-accordion-block {

  .accordion-item {
    border: none;
    border-bottom: 1px solid #1F50A0;
    margin: 0;
  }

  .accordion-wrap .accordion-item .accordion-header {
    background: #FBFBFB;
    padding: 23px 25px 29px 25px;
    justify-content: start;

    &:after {
      content: '';
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      width: 72px;
      height: 72px;
      background: url(assets/images/plus-blue.svg) 100% no-repeat;
      transition: .75s;
    }
  }

  .accordion-wrap .accordion-item.active .accordion-header {

    &:after {
      content: '';
      background: url(assets/images/minus-blue.svg) 100% no-repeat;
    }
  }

  .accordion-header.-has-icon {
    border-left: none;

    .title {
      padding-left: 0;
    }
  }

  .accordion-header {
    min-height: 103px;
    padding: 0 !important;
    border-left: 5px solid #1F50A0;

    .accordion-top-content {
      width: 52%;
      padding: 25px 0;
    }

    .image-wrap.-type-1 {
      background: #5CB97F;
    }

    .image-wrap.-type-2 {
      background: #EF7D00;
    }

    .image-wrap {
      margin-right: 25px;
      position: relative;
      width: 91px;
      height: 103px;

      span {
        font-family: $font_bold;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 162.8%;
        text-align: center;
        color: #ffffff;
      }

      .type-wrap-inner {
        position: relative;
        height: 60%;
      }

      .type-wrap {
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        transform: translate(0, -50%);
      }
    }

    .title {
      font-family: $font_bold;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 106.8%;
      color: #1F50A0;
      margin: 0;
      padding: 0 0 0 24px;
    }

  }

  .accordion-wrap .accordion-content {
    background: #FBFBFB;
    overflow: auto;

    h1, h2, h3, h4, h5, h6 {
      color: #5CB97F;
    }

    p {
      font-family: $font_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 162.8%;
      color: #000000;
    }

    h5 {
      margin: 0;
      padding: 0;
    }
  }

}

.people-block {

  .accordion-item {
    border: none;
    border-bottom: 1px solid #5CB97F;
    margin: 0;
  }

  .accordion-wrap .accordion-item .accordion-header {
    background: #FBFBFB;
    padding: 23px 25px 29px 25px;
    justify-content: start;

    &:after {
      content: '';
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      width: 72px;
      height: 72px;
      background: url(assets/images/plus.svg) 100% no-repeat;
      transition: .75s;
    }
  }

  .accordion-wrap .accordion-item.active .accordion-header {

    &:after {
      content: '';
      background: url(assets/images/minus.svg) 100% no-repeat;
    }
  }

  .accordion-header {

    .accordion-top-content {
      width: 52%;
    }

    .image-wrap {
      margin-right: 27px;
    }

    .name {
      font-family: $font_bold;
      font-style: normal;
      font-weight: bold;
      font-size: 23px;
      line-height: 120.8%;
      color: #5CB97F;
      margin: 0;
      padding: 0;
    }

    .program {
      font-family: $font_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 120.8%;
      color: #1F50A0;
    }

    .description {
      margin-top: 12px;

      p, a, li, span {
        font-family: $font_bold;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 162.8%;
        color: #000000;
      }

    }

  }

  .accordion-wrap .accordion-content {
    background: #FBFBFB;

    h1, h2, h3, h4, h5, h6 {
      color: #5CB97F;
    }

    p {
      font-family: $font_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 162.8%;
      color: #000000;
    }

    h5 {
      margin: 0;
      padding: 0;
    }
  }

  .left-right-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .left, .right {
      width: 50%;
    }

    .right {
      width: calc(50% - 20px);
    }
  }

  .accordion-wrap .accordion-content .quote {
    border-left: 3px solid #5CB97F;
    margin-left: 27px;
    padding-left: 25px;

    p {
      font-family: $font_light;
      font-style: normal;
      font-weight: 300;
      font-size: 25px;
      line-height: 132.8%;
      color: #5CB97F;
    }

  }

}

.tabs-block {
  margin-top: 40px;

  table {
    tbody tr:first-child {
      background: #1F50A0;

      td {
        color: #fff;
        border-color: #fff;
        text-align: center;
      }
    }

    td {
      border-color: #1F50A0;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: start;
    padding: 0;
  }

  h2, h3 {
    font-family: $font_bold;
  }

  .tab-columns {
    display: flex;
    flex-wrap: wrap;
    margin: 44px 0 69px 0;

    .tab-column-1 {
      width: 65%;

      p, a, li, span {
        font-size: 14px;
      }
    }

    .tab-column-2 {
      width: 35%;
    }
  }

  .main-info-block-wrap {
    background: #FBFBFB;
    border: 1px solid #EEEEEE;
    padding: 23px 28px;
    margin-top: 32px;

    h1, h2, h3, h4, h5, h6 {
      color: #5CB97F;
    }
  }

  .text-before {
    margin: 44px 0 32px 0;

    h5 {
      color: $active_color;
    }

  }

  .text-after {
    margin: 32px 0 5px 0;

    h5 {
      color: $active_color;
    }

  }

  .image-text-tab.no-background .text-rows {
    width: 100%;
  }

  .image-text-tab {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 43px 0;

    .text-rows, .background {
      width: calc(50% - 10px);
    }

    p, a, li, span {
      font-size: 14px;
    }

    .background {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;

      .content {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translate(0, -40%);
        text-align: center;
      }

      .btn {
        font-family: $font_medium;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
        background: #5CB97F;
        border-radius: 38px;
        padding: 17px 70px;

        &:hover {
          background: #1F50A0;
        }
      }
    }

    .text-content {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      .image {
        width: 15%;
        margin-right: 25px;
      }

      .text-content {
        width: calc(85% - 25px);
        overflow: auto;
      }
    }
  }

  .tabs {
    overflow: hidden;
    margin: 0;

    li {
      padding: 15px;
      margin-right: 2px;
      background: #FBFBFB;
      width: 300px;
      text-align: center;
      border: 1px solid #EEEEEE;
      cursor: pointer;

      h5 {
        margin: 0 !important;
        padding: 0 !important;
        text-transform: uppercase;
        color: #000000;
        font-family: $font_regular;
      }
    }

    li.active {
      z-index: 10;
      background: #ffffff;
      border-top: 5px solid #5CB97F;
      border-bottom: 0;
    }

  }

  .tab-content {
    padding: 0;
    margin: 0;

    li.single-tab {
      display: none;
      width: 100%;

      ul.tab-content {

        li.tab-open {
          display: block !important;
        }

        li.single-tab {
          display: none;
        }

      }

      li {
        display: block;
      }
    }

    li.tab-open {
      display: block;
    }

  }

}

.contacts-block {
  padding: 0;

  .layout-personnel-w-text, .layout-personnel {
    padding: 65px 0;
  }

  .layout-personnel {
    background: #F3F3F3;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .text-before {
    margin-bottom: 40px;

    h2, h3, h4, h5, h6 {
      color: $active_color;
    }
  }

  ul.personnel {

    li {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
    }

    li:last-child {
      margin-bottom: 0;
    }

    .image-wrap {
      margin-right: 22px;

      img {
        max-width: 215px;
        max-height: 215px;
        width: auto;
        height: auto;
      }
    }

    .personnel-info {
      max-width: 245px;

      p, a {
        margin-top: 0;
        margin-bottom: .3rem;
        font-family: $font_regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 126.8%;
        color: #000000;
        display: block;
      }
    }

  }

  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .column-1, .column-2 {
    width: calc(50% - 10px);
  }

  .column-1 {
    padding: 0 10px 0 0;
  }

  .column-2 {
    padding: 0 0 0 10px;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }

    .bottom-wrap {
      background: #1F50A0;
      padding: 62px 50px 50px 50px;
      margin-top: -5px;

      p, a, li, h2, h3, h4, h5, h6 {
        color: #ffffff;
        line-height: normal;
      }
    }

  }


}

.news-block.-single-page-block {
  padding: 58px 0;

  li {
    width: calc(33.3% - 10px);
    margin-bottom: 11px;
    position: relative;
  }

  .news-title {
    font-family: $font_bold;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 106.8%;
    color: #5CB97F;
  }

  .actions {
    text-align: center;
  }

  .btn {
    font-family: $font_medium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    background: #5CB97F;
    border-radius: 38px;
    padding: 17px 20px;
    display: inline-block;
    max-width: 318px;
  }

  article {
    max-width: unset;
    margin-bottom: 0;
  }

  .link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .content {
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: space-between;

    .inner-content {
      padding: 25px 40px;
      position: absolute;
      bottom: 0;
    }

    .meta {
      margin-top: 10px;
    }

    .image-wrap {
      background-size: cover;
      position: relative;
      width: auto;
      height: 250px;
      overflow: hidden;
      margin: auto;
      display: flex;

      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h3 {
      position: relative;
      z-index: 100;
      max-width: 815px;
    }

    .dates {
      width: auto;
      display: inline-block;
      position: relative;
      z-index: 100;

      &::after {
        content: "";
        border-bottom: 1px solid #EF7D00;
        position: relative;
        width: 40px;
        display: block;
        margin: 5px 0 23px 0;
      }

      .month {
        font-family: $font_regular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 106.8%;
        color: #FFFFFF;
        display: block;
        text-transform: capitalize;
      }

      .day {
        font-family: $font_bold;
        font-style: normal;
        font-weight: bold;
        font-size: 55px;
        line-height: 106.8%;
        text-align: left;
        color: #FFFFFF;
        display: block;
      }
    }

    h3 {
      font-family: $font_medium;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
      color: #FFFFFF;
    }

    .image-wrap {
      background-size: cover;
    }
  }

}

.news-block.-front-page {

  .content {
    li:hover {
      margin: 0 -10px;
      transition: .75s;

      article {
        background: #ffffff;
        padding: 10px;
        margin: 0 -10px;
        margin-bottom: 35px;
      }

    }
  }

}

.news-block {
  padding: 80px 0;
  background: #F3F3F3;

  article {
    max-width: 400px;
    margin: auto;
    margin-bottom: 35px;
  }

  .heading {
    text-align: left;
    padding: 0 0 20px 0;
    margin: 0;

    span {
      display: block;
      font-family: $font_bold;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 106.8%;
      color: #1F50A0;
      margin: 3px 0;
    }

    span:first-child {
      color: #5CB97F;
    }

    span:nth-child(2) {
      color: #5CB97F;
    }
  }

  .column-1, .column-2, .column-3 {
    width: calc(33.3% - 30px);
    padding: 0 15px;
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
  }

  .btn {
    border: 1px solid #5CB97F;
    color: #5CB97F;
    margin-top: 30px;

    &:hover {
      background: #5CB97F;
      color: #FFFFFF;
      transition: .5s;
    }
  }

  .news-title {
    font-family: $font_bold;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 106.8%;
    color: #1F50A0;
    margin-bottom: 20px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    justify-content: start;

    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 106.8%;
      color: #000000;
      width: 100%;
    }
  }

}

article {

  .study-content {
    text-align: center;

    h4 {
      font-family: $font_bold;
      margin-top: 18px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 106.8%;
      padding: 0 20px;
      text-transform: uppercase;
    }

    .excerpt {
      margin-top: 6px;
      padding: 0 10px;

      p {
        font-family: $font_regular;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 106.8%;
        text-align: center;
        color: #A8A8A8;
      }
    }
  }

  .placeholder-image {
    background: #F3F3F3;
  }

  .image-wrap {
    position: relative;
    height: 200px;
    width: 287px;
    overflow: hidden;

    img {
      transition: .25s;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    &:hover {
      img {
        transform: scale(1.2);
        transition: .5s;
      }
    }
  }

  .placeholder {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .meta {
    font-family: $font_regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 106.8%;
    color: #A8A8A8;
    margin-bottom: 5px;
    display: block;
  }

  .more {
    font-family: $font_regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 106.8%;
    text-decoration-line: underline;
    color: #1F50A0;
    margin-top: 5px;
  }

}

.files-block {

  ul {
    padding: 0;
    margin: 15px 0 50px 0;
    list-style: none;
  }

  li {
    border: 1px solid #1F50A0;
    padding: 18px 17px 13px 17px;
    display: flex;
    flex-wrap: wrap;
  }

  i {
    margin-right: 15px;
  }

  p {
    font-family: $font_regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 106.8%;
    color: #000000;
    margin: auto 0;
  }

  a {
    font-family: $font_bold;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 106.8%;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #5CB97F;
    margin: auto 0 auto auto;

    &:hover {
      color: #1F50A0;
    }
  }

}

.gallery-carousel {

  .swiper-wrapper {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .gallery-slider {
    overflow: hidden;
  }

  .swiper-slides-pagination {
    text-align: center;
  }

  .background {
    height: 672px;
    background-size: cover;
    background-position: center;
  }

  .swiper-slides-pagination {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;

    .swiper-pagination-bullet-active {
      background: #C4C4C4 !important;
      border-color: #C4C4C4 !important;
      height: 17px !important;
      width: 17px !important;
      margin: auto 3px !important;
    }

    .swiper-pagination-bullet {
      background: transparent;
      border: 1px solid #C4C4C4;
      height: 13px;
      width: 13px;
      opacity: 1;
      margin: 2px 3px;
      outline: none;
    }

  }

}

.studies-block {
  padding: 75px 0 95px 0;

  .study-slider-wrap {
    position: relative;
  }

  .swiper-next-study {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 90px;
    right: -20px;
    background: url('assets/images/arrow-short.svg') 100% no-repeat;
    transform: rotate(180deg);
    cursor: pointer;
    outline: none;
  }

  .swiper-prev-study {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 90px;
    left: -20px;
    background: url('assets/images/arrow-short.svg') 100% no-repeat;
    cursor: pointer;
    outline: none;
  }

  .swiper-studies-pagination {
    text-align: center;
    margin-top: 40px;

    .swiper-pagination-bullet-active {
      background: #C4C4C4 !important;
      border-color: #C4C4C4 !important;
      height: 17px !important;
      width: 17px !important;
      margin: auto 3px !important;
    }

    .swiper-pagination-bullet {
      background: transparent;
      border: 1px solid #C4C4C4;
      height: 13px;
      width: 13px;
      opacity: 1;
      margin: 2px 3px;
      outline: none;
    }

  }

  .studies-title {
    text-align: center;
    margin-bottom: 48px;

    span {
      font-family: $font_bold;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 106.8%;
      text-align: center;
      color: #49B170;
    }

    span:last-child {
      color: #1F50A0;
    }
  }

  .study-slider {
    overflow: hidden;

    article {
      max-width: 287px;
      margin: 0 auto;
    }
  }

  ul {
    padding: 0;
    list-style: none;
    margin: 0;
  }

  .actions {
    text-align: center;
    margin-top: 70px;
  }

  .btn {
    background: #49B170;
    color: #FFFFFF;
    transition: .5s;

    &:hover {
      background: #1F50A0;
    }
  }

}

.info-image-blocks {
  padding: 100px 0;

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin: 0;

    li {
      width: 33.3%;
      height: 420px;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: .5s;
        background: linear-gradient(0deg, rgba(38, 90, 166, 0.7), rgba(38, 90, 166, 0.7));
        opacity: 0;
      }

      &:hover::after {
        content: "";
        opacity: 1;
      }
    }
  }

  a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  h4 {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    left: 0;
    right: 0;
    font-family: $font_bold;
    font-style: normal;
    font-weight: bold;
    font-size: 43px;
    line-height: 50px;
    color: #FFFFFF;
    z-index: 100;
  }

}

.info-points-block {
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  .wrap {
    padding: 120px 0;
  }

  .quotes-wrap {
    overflow: hidden;
  }

  .column-1 {
    text-align: center;

    img {
      border-radius: 100%;
      margin-bottom: 20px;
    }

    .text {
      max-width: 662px;
      text-align: left;

      .author {
        text-align: left;
        float: right;
        font-weight: 300;
        font-size: 30px;
        line-height: 138.8%;
      }

      p, a, span, li {
        font-family: $font_light;
        color: #FFFFFF;
        font-style: italic;
        font-weight: 300;
        font-size: 38px;
        line-height: 138.8%;
      }
    }
  }

  .column-2 {

    .actions {
      text-align: center;
      width: 88%;
    }

    .btn {
      font-family: $font_medium;
      border: 1px solid #FFFFFF;
      transition: .5s;

      &::after {
        content: ">";
        display: inline-block;
        margin-left: 5px;
        transition: .75s;
      }

      &:hover {
        color: #ffffff;
        background: $active_color;

        &::after {
          content: ">";
          transform: translate(5px, 0px);
        }
      }
    }

    .heading {
      margin-bottom: 65px;
      margin-left: 35px;
      text-align: center;

      span:first-child {
        color: #49B170;
      }

      span {
        font-family: $font_bold;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 106.8%;
        color: #FFFFFF;
      }

    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .point {
      display: flex;
      position: relative;
      margin-bottom: 37px;

      .number {
        background: #49B170;
        width: 121px;
        height: 121px;
        min-width: 121px;
        min-height: 121px;
        display: inline-block;
        border-radius: 100%;
        margin-right: 50px;
        position: relative;

        span {
          color: #FFFFFF;
          margin: auto;
          font-family: $font_light;
          font-style: normal;
          font-weight: 300;
          font-size: 60px;
          line-height: 106.8%;
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: auto;
          text-align: center;
          height: auto;
          transform: translate(0%, 25%);
        }
      }
    }

    h3 {
      color: #FFFFFF;
      font-family: $font_bold;
      font-weight: bold;
      font-style: normal;
      font-size: 18px;
      line-height: 106.8%;
      margin-top: .5rem;
    }

    p, a, span, li {
      font-family: $font_regular;
      color: #FFFFFF;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 106.8%;
    }
  }

  .column-1, .column-2 {
    width: calc(50% - 30px);
    padding: 0 15px;
  }

}

.block-grid-block.-design-2 {

  .single-inner-block .title {
    color: #ffffff;
  }

  .single-inner-block.-inner-1 {

    .background, .text-content {
      width: 50%;
    }

    .text-content .btn {
      margin-top: 12px;

      &:hover {
        border: 1px solid #fff;
      }
    }

  }

  .grid-block:last-child {

    .single-inner-block.-inner-1:last-child {

      .text-content {
        background: #5CB97F;
      }

    }

  }

}

.block-grid-block {
  margin-top: 48px;
  margin-bottom: 47px;

  .grid-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 3px;
  }

  .background {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }

  .single-inner-block.-has-text .content {
    position: absolute;
    top: 17px;
    bottom: 17px;
    left: 18px;
    right: 18px;
    transform: none;
    background: #1F50A0;
  }

  .single-inner-block {
    position: relative;

    .title {
      font-family: $font_bold;
      font-style: normal;
      font-weight: bold;
      font-size: 43px;
      line-height: 50px;
      text-align: center;
      color: #000000;
      padding-top: 0;
      padding-bottom: 35px;
      margin: 0;
    }

    .btn {
      font-family: $font_medium;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #FFFFFF;
      background: #5CB97F;
      border-radius: 38px;
      padding: 17px 70px;

      &:hover {
        background: #1F50A0;
      }
    }

    .text {
      text-align: left;
      padding: 42px 19px 30px 19px;
    }

    h1, h2, h3, h4, h5, h6 {
      color: #ffffff;
    }

    p, a, li, span {
      font-family: $font_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 151.8%;
      color: #ffffff;
    }

    .content {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translate(0, -40%);
      text-align: center;
    }
  }

  .single-inner-block.-reverse {

    .background {
      order: 2;
    }

  }

  .single-inner-block.-inner-1 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .background {
      width: calc(33.3% - 2px);
      min-height: 417px;
    }

    .text-content {
      width: calc(66.6% + 3px);
      position: relative;
      background: #1F50A0;
    }

    .text {
      padding: 37px 39px 17px 39px;
    }

    p, a, li, span {
      font-size: 18px;
      color: #ffffff;
      margin-top: 0;
    }

  }

  .single-inner-block.-inner-2 {
    min-height: 417px;
    width: calc(50% - 2px);
  }

  .single-inner-block.-inner-3 {
    min-height: 417px;
    width: calc(33.3% - 2px);
  }
}

.event-calendar-block {
  padding: 50px 0 100px 0;
  overflow: hidden;

  li {
    margin-bottom: 10px;
    float: left;
    display: inline-block;
  }

  li:first-child {
    width: 100%;

    .image-wrap {
      height: 435px;
    }

  }

  li:nth-child(2) {
    width: 50%;

    .image-wrap {
      height: 435px;
    }
  }

  li:nth-child(3), li:nth-child(4), li:nth-child(5), li:nth-child(6) {
    width: calc(25% - 10px);
    margin-left: 10px;
    margin-bottom: 10px;

    .image-wrap {
      height: 213px;
    }

    .content-dates::after {
      content: "";
      margin: 5px 0 5px 0;
    }

    .inner-content {
      padding: 12px 12px;
    }

    h3 {
      display: none;
    }
  }

  .link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .content {
    list-style: none;
    padding: 0;
    margin: 0;

    .inner-content {
      padding: 25px 40px;
      position: absolute;
      bottom: 0;
    }

    .image-wrap {
      width: 100%;
    }

    h3 {
      position: relative;
      z-index: 100;
    }

    .dates {
      width: auto;
      display: inline-block;
      position: relative;
      z-index: 100;

      &::after {
        content: "";
        border-bottom: 1px solid #EF7D00;
        position: relative;
        width: 40px;
        display: block;
        margin: 5px 0 23px 0;
      }

      .month {
        font-family: $font_regular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 106.8%;
        color: #FFFFFF;
        display: block;
        text-transform: capitalize;
      }

      .day {
        font-family: $font_bold;
        font-style: normal;
        font-weight: bold;
        font-size: 55px;
        line-height: 106.8%;
        text-align: left;
        color: #FFFFFF;
        display: block;
      }
    }

    h3 {
      font-family: $font_medium;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
      color: #FFFFFF;
    }

    .image-wrap {
      background-size: cover;
    }
  }

  .calendar-wrap {
    background: #F3F3F3;
    padding: 42px 30px;
    height: calc(100% - 95px);

    .arrow-link {
      font-family: $font_bold;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
      margin: 30px 0;
      display: block;
    }
  }

  .event-heading {
    margin-bottom: 42px;

    .column-3 {
      width: calc(50% - 20px);
      padding: 0 0 0 20px;
    }

    .column-2 {
      width: calc(15% - 40px);
      padding: 0 20px;
    }

    .column-1 {
      width: calc(35% - 20px);
      padding: 0 0 0 20px;
    }

    .day {
      font-family: $font_bold;
      font-style: normal;
      font-weight: bold;
      font-size: 55px;
      line-height: 106.8%;
      text-align: left;
      color: #1F50A0;
      display: block;
    }

    .month {
      font-family: $font_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 23px;
      line-height: 106.8%;
      text-align: left;
      color: #1F50A0;
      display: block;
      text-transform: capitalize;
    }

    h2 {
      font-family: $font_bold;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 106.8%;
      color: #5CB97F;
      margin: 0;
    }

    p {
      font-family: $font_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 106.8%;
      color: #A8A8A8;
      margin: 8px 0 0 0;
    }

  }

  article {
    position: relative;
  }

  article:hover {

    .image-wrap {
      position: relative;
      transition: .5s;

      &:after {
        content: "";
        background: rgba(0, 0, 0, 0.5);
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        position: absolute;
        transition: .5s;
      }
    }
  }

  .events-list {

    .column-1 {
      width: calc(70% - 20px);
      padding: 0 0 0 20px;
    }

    .column-2 {
      width: calc(30% - 20px);
      padding: 0 0 0 20px;

      h4 {
        font-family: $font_bold;
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 106.8%;
        color: #1F50A0;
        margin-bottom: 55px;
      }
    }

  }

}

@media only screen and (max-width: 1250px) {

  .statistics-block {

    li:nth-child(4n)::after {
      content: none;
    }

    li {
      width: 33.3%;

      &:after {
        transform: rotate(15deg) translate(-30px, 0);
        top: 0;
      }
    }

  }

}

@media only screen and (max-width: 1200px) {

  .carousel-block h2 {
    font-size: 40px;
  }

  .info-points-block .column-1 .text {
    p, a, li, span {
      font-size: 30px;
      padding: 20px;
    }
  }

}

@media only screen and (max-width: 1080px) {

  .big-accordion-block .accordion-wrap .accordion-content {
    padding-left: 30px;
  }

  .big-accordion-block .accordion-header {
    min-height: 80px;
  }

  .big-accordion-block .accordion-header .accordion-top-content {
    padding: 0;
  }

  .archive-page, .studies-archive {
    ul.content li {
      width: calc(50% - 20px);
    }
  }

  .contacts-block {

    .column-1, .column-2 {
      width: 100%;
      padding: 0;
      margin: 10px 0;
    }

    .column-2 {

      .top-wrap {
        margin: auto;
        text-align: center;
      }

    }

    ul.personnel {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 33.3%;
        margin-bottom: 10px;
        display: block;

        .personnel-info {
          margin-top: 10px;
        }
      }
    }

  }

  .carousel-block {

    .swiper-wrapper .swiper-slide .background {
      background-size: cover !important;
    }

  }

  .info-image-blocks ul li {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    background-position: center;
  }

  .event-calendar-block {
    padding: 10px 0 10px 0;

    .events-list {

      .column-1, .column-2 {
        width: 100%;
        padding: 0;
      }
    }

  }

  .info-boxes .layout-1 ul {

    li {
      text-align: center;

      a {
        transform: none;
      }

      img {
        transform: none;
        display: block;
        margin: auto;
      }

      span {
        display: block;
      }

    }

    li.last-child {

      span {
        padding-right: 0;
        border-right: none;
      }

      a:after {
        content: "";
        display: block;
        margin: auto;
        transform: none;
      }

    }

  }

}

@media only screen and (max-width: 900px) {

  .tabs-block .image-text-tab {

    .text-rows, .background {
      width: 100%;
    }

    .text-rows {
      height: auto;
    }

    .background {
      height: 500px;
    }

    .text-content .image {
      width: 100%;
    }

    .text-content .text-content {
      width: 100%;
    }

  }

  .statistics-block {

    li:nth-child(4n)::after {
      content: "";
    }

    li:nth-child(2n-1)::after {
      content: none !important;
    }

    li {
      width: 50%;

      &:after {
        transform: rotate(15deg) translate(0, 0);
      }
    }

  }

}

@media only screen and (max-width: 800px) {

  .news-block.-single-page-block li {
    width: 100%;
  }

  .info-points-block .column-2 .actions {
    width: auto;
  }

  .block-grid-block .single-inner-block {
    width: 100% !important;
  }

  .block-grid-block .single-inner-block.-inner-3 {
    height: 500px;
  }

  .block-grid-block .single-inner-block.-inner-1 .background {
    width: 100%;
  }

  .block-grid-block .single-inner-block.-inner-1 .text-content {
    width: 100%;
  }

  body .post-content .wp-block-column {
    width: 100%;
    flex-basis: 100% !important;
  }

  .tabs-block .tab-columns {

    .tab-column-1, .tab-column-2 {
      width: 100%;
    }

  }

  .block-grid-block .grid-block {
    display: block;
  }

  .block-grid-block .single-inner-block.-inner-1 {

    .text {
      padding: 10px 10px 17px 10px;
    }

    p, a, li, span {
      font-size: 16px;
    }

    .title {
      font-size: 30px;
    }

  }

  .block-grid-block .single-inner-block.-reverse .background {
    order: 0;
  }

  .block-grid-block.-design-2 .single-inner-block.-inner-1 {

    .background, .text-content {
      width: 100%;
    }

  }

  .people-block .accordion-wrap .accordion-item .accordion-content {
    padding: 10px 0;
  }

  .people-block .accordion-wrap .accordion-item .accordion-header {
    display: block;
    padding: 25px 0 29px 0;

    &:after {
      content: "";
      right: 0;
      top: 10px;
      transform: none;
      position: absolute;
      background-size: 100% !important;
      height: 50px;
      width: 50px;
      display: block;
    }
  }

  .big-accordion-block, .people-block {

    .accordion-wrap .accordion-item {

      .accordion-content {
        padding-left: 20px;
      }

      .accordion-header {

        .accordion-top-content {
          width: 100%;
        }

        .image-wrap {
          margin-right: 10px;
        }

        &:after {
          content: "";
          right: 0;
          top: 0;
          transform: none;
          position: relative;
          background-size: 100% !important;
        }
      }

    }

  }

  .list-table-block .column {
    width: 100% !important;
  }

  .contacts-block ul.personnel li {
    width: 50%;
  }

  .info-points-block {

    .column-2 .heading {
      margin-left: 0;
    }

    .column-1 .text .author {
      max-width: unset;
      text-align: center;
      float: none;
    }

  }

  .info-boxes .layout-1 ul {
    margin: -30px 0 0 0;
  }

  .info-boxes .layout-2 ul {

    li {
      padding: 30px;
    }

    li::after {
      content: none !important;
    }

  }

  .info-points-block {

    .column-1 {
      .text {
        text-align: center;
        margin: 0 auto;
      }
    }

    .column-1, .column-2 {
      width: 100%;
    }

  }

  .news-block {
    padding: 20px 0 10px 0;

    .column-1, .column-2 {
      width: calc(50% - 30px);
    }

    .column-3 {
      margin: 20px 0;
      width: 100%;
    }

  }

  .event-calendar-block {

    .event-heading  .column-1 {
      width: 100%;
      margin-bottom: 10px;
    }

  }

  .info-boxes {

    .layout-1 ul li, .layout-2 ul li {
      width: 100%;
    }

  }

  .info-boxes .layout-1 .last-child {
    transform: none !important;

    a {
      transform: none !important;
    }

  }

}

@media only screen and (max-width: 700px) {

  .tabs-block .image-text-tab .background .btn {
    padding: 17px 30px;
  }

  .carousel-block {

    h1, .h1 {
      font-size: 30px;
      line-height: 32px;
    }

    p {
      font-size: 20px;
    }

    .navigation-buttons {
      bottom: 50px;
    }

  }

  .event-calendar-block {

    li:nth-child(2) {
      width: 100%;
    }

    li:nth-child(3), li:nth-child(5) {
      margin-right: 10px;
    }

    li:nth-child(3), li:nth-child(4), li:nth-child(5), li:nth-child(6) {
      width: calc(50% - 5px);
      margin-left: 0;
    }

  }

  .news-block {

    .heading {
      text-align: center;
    }

    .column-1 {
      text-align: center;
    }

    .column-1, .column-2 {
      width: 100%;
      margin: 10px 0;
    }

  }

  .studies-block {
    padding: 50px 0;

    .actions {
      margin-top: 30px;
    }
  }

}

@media only screen and (max-width: 600px) {

  .archive-page, .studies-archive {
    ul.content li {
      width: 100%;
    }
  }

}

@media only screen and (max-width: 500px) {

  .info-points-block {
    .row {
      margin: 0;
    }
  }

  .wp-block-quote {

    p {
      font-size: 40px;
    }

    cite {
      font-size: 20px;
    }

  }

  .statistics-block {

    li {
      width: 100%;
      &:after {
        content: none !important;
      }
    }

  }

  .contacts-block ul.personnel li {
    width: 100%;
  }

  .event-calendar-block .row {
    margin: 0;
  }

  .info-points-block {

    .quotes-wrap {
      padding-right: 15px;
    }

    .column-1 .text {

      p, a, li {
        font-size: 20px;
      }

      .author {
        font-size: 18px;
      }

    }

    .column-2 .point {

      .number {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        margin-right: 30px;
        margin-left: 10px;

        span {
          font-size: 26px;
        }

      }

    }

  }

  .info-image-blocks {
    padding: 20px 0;
  }

  .event-calendar-block .event-heading {

    .column-1, .column-2, .column-3 {
      padding: 0;
    }

    .column-2 {
      width: 100%;
    }

    .column-3 {
      width: 100%;
      margin-bottom: 20px;
    }

  }

  .studies-block .btn {
    font-size: 15px;
  }

  .info-image-blocks h4 {
    font-size: 30px;
  }

}
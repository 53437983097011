
i.search-icon {
  background: url('assets/images/search.svg') 100% no-repeat;
  width: 15px;
  height: 18px;
  display: block;
}

i.limited-icon {
  background: url('assets/images/limited.svg') 100% no-repeat;
  width: 16px;
  height: 18px;
  display: block;
}

i.arrow-button {
  background: url('assets/images/arrow-short.svg') 100% no-repeat;
  width: 20px;
  height: 30px;
  display: block;
  background-size: 100%;
  transform: rotate(90deg);
}

i.user-icon {
  background: url('assets/images/user.svg') 100% no-repeat;
  width: 14px;
  height: 14px;
  display: inline-block;
  transform: translate(-3px, 2px);
}

i.file-icon {
  background: url('assets/images/file.svg') 100% no-repeat;
  width: 20px;
  height: 25px;
  display: inline-block;
}

i.moodle {
  background: url('assets/images/moodle.svg') 100% no-repeat;
  width: 16px;
  height: 10px;
  display: inline-block;
}

i.youtube {
  background: url('assets/images/youtube.svg') 100% no-repeat;
  width: 33px;
  height: 33px;
  display: inline-block;
}

i.instagram {
  background: url('assets/images/instagram.svg') 100% no-repeat;
  width: 23px;
  height: 33px;
  display: inline-block;
}

i.facebook {
  background: url('assets/images/facebook.svg') 100% no-repeat;
  width: 25px;
  height: 33px;
  display: inline-block;
}

i.twitter {
  background: url('assets/images/twitter.svg') 100% no-repeat;
  width: 25px;
  height: 33px;
  display: inline-block;
}

i.linkedin {
  background: url('assets/images/linkedin.svg') 100% no-repeat;
  width: 25px;
  height: 33px;
  display: inline-block;
}

i.print {
  background: url('assets/images/print.svg') 100% no-repeat;
  width: 25px;
  height: 33px;
  display: inline-block;
}
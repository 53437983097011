
@keyframes gdpr-slide-up {
  0%, 70% {
    -webkit-transform: translateY(200px);
    -ms-transform: translateY(200px);
    transform: translateY(200px);
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes gdpr-slide-down {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(200px);
    -ms-transform: translateY(200px);
    transform: translateY(200px);
  }
}

#gdpr-consent {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 10px;
  width: 100%;
  max-width: 800px;
  margin: auto;
  overflow: hidden;
  z-index: 9001;

  .cc-invisible {
    transition: 1s;
    transform: translateY(200px);
  }

  .cc-window {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    font-family: $font_regular;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 0.015px 5px 0 rgba(0,0,0,0.24);
    animation-duration: 1.45s;
    animation-name: gdpr-slide-up;
  }

  .cc-compliance {
    margin: auto;
  }

  .cc-dismiss {
    position: relative;
    padding: 8px 18px;
    display: block;
    cursor: pointer;
    font-size: 15px;
    background: #1F50A0;
    background-position: center;
    margin: 0;
    color: #FFFFFF !important;
  }

  .cc-message {
    max-width: 80%;
    font-size: 14px;
    color: #000000;
    text-align: left;
    font-family: $font_regular;
    margin: 0;

    a {
      color: #000000;
      text-decoration: underline;
    }

    p, a {
      font-size: 14px;
      font-family: $font_regular;
      margin: 0;
    }
  }

}

@media only screen and (max-width: 990px) {

  #gdpr-consent {
    .cc-message, .cc-compliance {
      max-width: 100%;
      margin-bottom: 10px;
    }
  }
}

body {
  margin: 0;
}

.wrap {
  max-width: 1340px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.inner {
  padding: 50px 0;
}

.clear {
  clear: both;
  display: block;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font_regular;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  color: #000000;
  margin-top: 0;
  margin-bottom: .5rem;
}

h1, .h1 {
  font-size: 60px;
  line-height: 110%;
}

h2, .h2 {
  font-size: 40px;
  line-height: 120%;
}

h3, .h3 {
  font-size: 24px;
  line-height: 130%;
}

h4, .h4 {
  font-family: $font_bold;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 106.8%;
}

h5, .h5 {
  font-family: $font_bold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 106.8%;
}

h6, .h6 {
  font-size: 14px;
  line-height: 130%;
}

a {
  text-decoration: none;
}

p {
  margin-top: 0;
}

p, a, li, span, th, td {
  font-family: $font_regular;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: normal;
  color: #000000;
}

.share {
  background: #1F50A0;
  padding: 20px;

  a {
    display: inline-block;
    margin-right: 10px;
  }
}

.input, textarea {
  font-family: $font_regular;
  font-weight: normal;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  background: transparent;
  color: #666666;

  &::placeholder {
    font-family: $font_regular;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 150%;
  }
}

.form-container {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 28px 38px;
  margin: 0 auto;

  .invalid-field {
    border: 1px solid #b31313;
  }

  .invalid-field[type="checkbox"]::before {
    content: "";
    border: 1px solid #b31313 !important;
  }

  label {
    display: block;
    margin-bottom: 6px;
    font-family: $font_regular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000000;
  }

  a {
    font-family: $font_regular;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  }

  .form-entry {
    margin-bottom: 14px;
  }

  .form-entry-privacy {
    margin: 19px 0 21px 0;
  }

  input[type="checkbox"], .input[type="radio"] {
    width: auto;
    height: auto;
    min-width: unset;
    max-width: unset;
  }

  input[type="checkbox"] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    margin-right: 15px;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    width: 13px;
    height: 13px;
    outline: none;
    position: relative;

    &:before {
      content: "";
      height: 26px;
      width: 26px;
      background: #1F50A0;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 5px;
      display: inline-block;
      position: relative;
      z-index: 1;
      margin-top: -8px;
      margin-left: -3px;
    }

    &:checked:after {
      content: "";
      background: url('assets/images/checkbox.svg') 100% no-repeat;
      height: 12px;
      width: 14px;
      display: inline-block;
      transform: translate(3px, -26px);
      position: relative;
      z-index: 10;
    }
  }

  input[type="submit"] {
    font-family: $font_regular;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    background: #1F50A0;
    border-radius: 5px;
    padding: 14px 10px 16px 10px;
    cursor: pointer;
    width: 100%;

    &:hover {
      background: $active_color;
    }
  }

  input, textarea {
    font-family: $font_regular;
    font-weight: normal;
    vertical-align: middle;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
    border-radius: 5px;
    background: transparent;
    color: #000000;
    min-width: 420px;

    &::placeholder {
      font-family: $font_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
    }
  }

}

.form-wrap {
  display: flex;
  flex-wrap: wrap;

  a {
    color: $active_color;
  }

  .info {
    color: $active_color;
    margin: 10px 0;
  }

  .error {
    color: #b31313;
    margin: 10px 0;
    text-align: left;
    max-width: 420px;
  }

}

.studies-archive {

  ul.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;

    .article-title {
      margin: 0;
      padding: 24px 17px;
      position: relative;

      &:after {
        content: "";
        background: url("assets/images/arrow-white.svg") no-repeat;
        width: 10px;
        height: 18px;
        display: block;
        position: absolute;
        margin: auto;
        right: 20px;
        top: 0;
        bottom: 0;
      }
    }

    h4 {
      margin: 0;
      padding: 0;
      text-align: left;
      color: #fff;
      max-width: 448px;
    }

    .image-wrap {
      position: relative;
      width: auto;
      height: 442px;
      overflow: hidden;
      margin: auto;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    li {
      width: calc(50% - 34px);
      margin-bottom: 34px;
      background: #5CB97F;
    }
  }
}

.archive-page {

  ul.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;

    .meta {
      margin-top: 10px;
    }

    .image-wrap {
      position: relative;
      width: auto;
      height: 250px;
      overflow: hidden;
      margin: auto;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    li {
      width: calc(33.3% - 20px);
      margin-bottom: 30px;
    }
  }
}

.post-content {

  .wp-block-media-text__media img,
  .wp-block-media-text__media video {
    height: auto;
    width: auto;
    max-width: 100%;
  }

  p, a, li, span {
    font-size: 18px;
  }

  li {
    margin-bottom: .5rem;
  }

  h2, h3, h4, h5, h6 {
    padding: 5px 0 15px;
  }

  a {
    color: $active_color;
  }

  ul:not([class]) {
    list-style: none;

    li {
      margin-left: 1em;
      width: auto;
    }

    li::before {
      content: "\2022";
      color: #9AC328;
      font-size: 16px;
      display: inline-block;
      transform: translate(-5px, -2px);
      width: 1em;
      margin-left: -1em;
    }

  }

  .wp-block-table {
    margin: 0;
  }

  .wp-block-buttons {

    .wp-block-button {
      margin-right: 30px;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    a {
      color: #ffffff;
      padding: 16px 52px;
    }

  }

  table {

    th, td {
      width: 150px;
    }

    td {
      padding: 10px 5px;
    }
  }

}

.single-post {

  .meta {
    margin-bottom: 12px;

    span {
      font-family: $font_regular;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      letter-spacing: -0.02em;
      color: #49A048;
      display: inline-block;
    }

  }

}

.btn, .wpcf7-submit {
  font-family: $font_medium;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  text-decoration: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 16px 35px;
  font-size: 18px;
  line-height: 21px;
  border-radius: 38px;
  user-select: none;
  background: transparent;
  text-transform: uppercase;
}

.wpcf7-form-control-wrap input,
.wpcf7-form-control-wrap textarea {
  margin: 0 0 10px 0;
  border-bottom: 1px solid #222 !important;
  width: 100%;
  max-width: 500px;
}

.input,
.wpcf7-form-control-wrap input,
.wpcf7-form-control-wrap textarea {
  font-family: $font_regular;
  font-weight: normal;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  background: transparent;
  color: #666666;

  &::placeholder {
    font-family: $font_regular;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.42857143;
    color: #777777;
  }
}

.search-page {

  a {
    color: $active_color;

    &:hover {
      color: #222;
    }
  }

  .search-result {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }

  .search-result:last-child {
    border: none !important;
  }
}

.page-sticky-sidebar.-open {
  z-index: 1000000;
}

.page-sticky-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  display: flex;

  .sidebar-item:first-child {
    top: 30vh;
  }

  .sidebar-item {
    position: relative;
    text-align: center;
    width: 80px;
    height: 80px;
    padding: 4px;
    background: #1F50A0;
    box-shadow: 0 1px 5px -2px rgba(42,42,42,0.4);
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    cursor: pointer;
  }

  .inner-sidebar-item {
    margin: auto;
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate(0%, calc(50% - 50px));
  }

  img {
    height: 40px;
    width: 40px;
    display: block;
    margin: auto;
  }

  span {
    display: block;
    color: #fff;
    word-break: break-word;
    font-size: 13px;
  }

  .sidebar-content-wrap {
    height: 100vh;
    box-shadow: 0 1px 5px -2px rgba(42,42,42,0.4);
    background: #fff;

    .sidebar-content.content-expanded {
      max-width: 200px;
      margin-right: 20px;
      margin-left: 20px;
    }

    .sidebar-content {
      max-width: 0;
      width: 100vw;
      margin-top: 40px;
      margin-bottom: 0;
      transition: max-width 0.5s;

      .inner-sidebar-content {
        overflow: auto;
        height: calc(100vh - 80px);

        &::-webkit-scrollbar {
          width: 2px;
        }

        &::-webkit-scrollbar-thumb {
          background: #000000;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: transparent;
        }
      }
    }
  }
}

.header-image {
  padding: 85px 0;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  margin: auto;
  max-width: 1920px;

  h1, .h1 {
    padding: 0;
    font-family: $font_regular;
    font-size: 50px;
    line-height: normal;
    color: #FFFFFF;
    border-left: 5px solid #5CB97F;
    padding-left: 20px;
  }
}

.breadcrumbs {
  padding: 0;
  margin: 22px 0;
  position: relative;

  li {
    display: inline-block;
    vertical-align: top;
    list-style: none;
    padding: 0 10px 10px 0;

    a {
      font-family: $font_light;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: normal;
      color: #1F50A0;
    }

    &:last-child span {
      display: none;
    }

    span {
      display: inline-block;
      margin-right: -8px;
      margin-left: 2px;
      font-family: $font_light;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 106.8%;
      color: #1F50A0;
    }

  }
}

.pagination {
  text-align: center;
  margin: 20px auto;

  .current {
    background: #ECF5EB;
    border-radius: 100%;
    border: 1px solid #49A048 !important;
  }

  .page-numbers {
    position: relative;
    padding: 6px 8px 10px 8px;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-decoration: none;
    color: #222222;
    border: 1px solid transparent;
  }

  .prev:after {
    content: "";
    background: url(assets/images/arrow.svg) 100% no-repeat;
    display: inline-block;
    width: 10px;
    height: 20px;
    margin: 0;
    position: relative;
    transform: translate(0px, 5px) rotate(180deg);
  }

  .next:after {
    content: "";
    background: url(assets/images/arrow.svg) 100% no-repeat;
    display: inline-block;
    width: 10px;
    height: 20px;
    margin: 0;
    position: relative;
    transform: translate(0px, 5px);
  }
}

.row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
}

.column-1 {
  width: calc(22% - 20px);
  padding: 0 20px 0 0;
}

.column-2 {
  width: calc(77% - 30px);
  padding: 0 0 0 30px;
}

.column-full {
  width: 100%;
}

.page-sidebar {
  background: #F5F5F5;
  border-radius: 6px;
  padding: 30px;

  h3 {
    color: $active_color;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-left: 16px;

    li {
      position: relative;
    }
  }

  a {
    font-family: $font_regular;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #222222;
    padding: 10px 0;
    display: block;
  }

  .current > a, .current_parent > a {
    color: $active_color;

    &::before {
      content: "";
      background: url('assets/images/arrow-active.svg') 100% no-repeat;
      width: 6px;
      height: 12px;
      display: inline-block;
      position: absolute;
      transform: translate(-14px, 4px);
    }
  }

}

.wp-block-image img {
  height: auto;
}

.single-page {
  padding-bottom: 60px;

  .news-block {
    background: transparent;
    padding: 0;
  }

  .wp-block-image img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .wp-block-column figcaption {
    font-family: $font_regular;
    font-size: 13px;
    text-align: right;
    color: #555;
  }

}

@media only screen and (max-width: 1380px) {

  .wp-block-media-text {
    display: flex;
    flex-wrap: wrap;
  }

}

@media only screen and (max-width: 1240px) {

  body {
    -moz-transform: none;
    zoom: 0.9;
    zoom: 90%;
  }

}

@media only screen and (max-width: 1080px) {

  body {
    -moz-transform: none;
    zoom: 1;
    zoom: 100%;
  }

}

@media only screen and (max-width: 1080px) {

  .column-1 {
    width: 100%;
    padding: 0;
  }

  .column-2 {
    width: 100%;
    padding: 0 20px;
  }

}

@media only screen and (max-width: 600px) {

  .form-container input, .form-container textarea {
    width: 100%;
    min-width: unset;
  }

  .post-content ul:not([class]) {
    padding-left: 3px;
  }

  #wp-admin-bar-wpseo-menu {
    display: none !important;
  }

  .page-sticky-sidebar .sidebar-item:first-child {
    top: 50vh;
  }

  .page-sticky-sidebar .sidebar-item {
    width: 40px;
    height: 40px;

    .inner-sidebar-item {
      transform: translate(0%, calc(50% - 30px));
    }

    span {
      display: none;
    }
  }

  .header-image {
    padding: 20px 0;
  }

  .single-page h1 {
    font-size: 35px;
  }

  .inner {
    padding: 20px 0;
  }

}
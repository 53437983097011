
#datepicker {
  width: 320px;
}

.ui-datepicker-title {
  font-size: 20px;
  font-family: $font_regular;
  color: #000000;
  display: inline-block;
  padding: 0 10px;

  span {
    font-size: 20px;
    font-family: $font_regular;
    color: #000000;
    display: inline-block;
  }

}

.ui-datepicker-calendar {
  margin: 0 -10px;
  width: 100%;

  span[title="Sunday"], span[title="Saturday"] {
    font-family: $font_bold;
    color: #000000;
  }

  a, span {
    font-family: $font_regular;
    font-style: normal;
    font-weight: 300;
    text-align: center;
    letter-spacing: -0.01em;
    width: 40px;
    display: block;
    height: 40px;
    font-size: 20px;
    line-height: 2;
    color: #999999;
  }

  tbody {
    a, span {
      color: #000000;
    }
  }

}

.ui-datepicker-header {
  display: flex;
  padding: 0 0 25px 0;
}

.ui-datepicker-prev {
  cursor: pointer;

  span {
    display: none;
  }

  &:after {
    content: "";
    background: url('assets/images/calendar-arrow.svg') 100% no-repeat;
    height: 10px;
    width: 35px;
    display: block;
    transform: translate(0px, 8px);
  }
}

.ui-datepicker-month {
  font-family: $font_bold;
  font-weight: bold;
}

.ui-datepicker-today {
  background: #FFFFFF;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
  border-radius: 100%;

  a {
    color: #000000 !important;
  }
}

.ui-datepicker-next {
  cursor: pointer;
  order: 3;

  span {
    display: none;
  }

  &:after {
    content: "";
    background: url('assets/images/calendar-arrow.svg') 100% no-repeat;
    height: 10px;
    width: 35px;
    display: block;
    transform: translate(0px, 8px) rotate(180deg);
  }
}

.datepicker-wrap {
  background: #EEE8D8;
  border-radius: 4px;
  padding: 5px;
  margin-top: 10px;

  .outline {
    box-sizing: border-box;
    border-radius: 3px;
    border: 2px dashed #fff;
    padding: 25px;
  }

  h4 {
    color: #373122;
    margin-bottom: 25px;
  }
}

body .ui-datepicker-calendar .infobox {
  background: white;
  position: absolute;
  bottom: 40px;
  width: 170px;
  transform: translate(-125px, 0px);
  display: inline-block;
  z-index: 100;
  padding: 20px;
  border: 1px solid #DEDEDE;
  text-align: left;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #ffffff;
    left: 40%;
    right: 0;
    transform: translate(0px, 15px);
    position: absolute;
    bottom: 0;
  }

  strong {
    font-family: $font_regular;
  }

  span {
    font-size: 12px;
    font-family: $font_regular;
    display: block;
    text-align: left;
    line-height: normal;
    height: unset;
    width: unset;
  }

  a {
    font-family: $font_regular;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #373122 !important;
    text-align: left;
    max-width: unset;
    display: block;
    margin-bottom: 10px;
    height: auto !important;
    width: auto !important;

    &:hover {
      color: #EF7D00 !important;
    }
  }

  a:last-child {
    margin-bottom: 0;
  }

}

.date-event-day {
  a {
    color: #EF7D00 !important;
  }
}

.date-current-day,
.date-previous-day {

}

.ui-datepicker-calendar {
  text-align: center;

  thead {
    th {
      text-align: center;
      line-height: 3;
    }
  }

  tr {
    position: relative;
    display: inline-block;
    padding: 3px 0;
  }

  td {
    text-align: center;
    margin: 1px;
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 500px) {

  .ui-datepicker-calendar td,
  .ui-datepicker-calendar a,
  .ui-datepicker-calendar span {
    width: 29px;
    height: 29px;
    line-height: 1.5;
  }

  .ui-datepicker-title {
    text-align: center;
  }

}